<template>
	<div class="body-function">
		<div style="display: inline-block; width: 100%; height: 100%">
			<div class="child-top" style="padding-top: 0">
				<div class="input-from">
					<el-date-picker
						v-model="stageDate"
						type="daterange"
						align="right"
						unlink-panels
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="create"
						class="picker_date"
					></el-date-picker>
					<el-cascader
						@change="getStaffList"
						style="float: left"
						placeholder="请选择运动队"
						clearable
						v-model="deparmentId"
						:options="departmentOptions"
						:show-all-levels="false"
						collapse-tags
						:props="{ children: 'children', label: 'name', value: 'uuid', emitPath: false, multiple: true }"
					></el-cascader>
					<el-select v-model="staffValue" placeholder="请选择运动员" @change="getStaffInfo">
						<el-option v-for="item in staffList" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
					</el-select>

					<div class="text_demo" @click="selectTextIndex" placeholder="请选择测试指标">
						<p v-if="!demoSelectData.length">请选择测试指标</p>
						<span v-for="(item, index) in demoSelectData" :key="index">
							{{ item.name }}
							<i class="el-icon-close" @click.stop="demoCancel(item.uuid, index)"></i>
						</span>
					</div>
				</div>

				<div class="operation-button">
					<el-button @click="savePersonAnalysis" class="new_btn" style="background: #0055e9" operation-button round>
						<span>保存为个体分析报告</span>
					</el-button>
				</div>
			</div>

			<div class="individual_top_content">
				<div class="member_content_top">
					<!-- <el-avatar :src="interDataList[0].head_img ? 'http://' + interDataList[0].head_img : circleUrl"></el-avatar> -->
					<img :src="personInfo.head_img ? 'http://' + personInfo.head_img : circleUrl" alt="" />
					<span>姓名: {{ personInfo.name }}</span>
					<span>性别：{{ personInfo.sex == 1 ? '男' : personInfo.sex == 2 ? '女' : '' }}</span>
					<span>身高：{{ personInfo.height }}cm</span>
					<span>体重：{{ personInfo.weight }}kg</span>
					<span>项目：{{ personInfo.sport_event_name }}</span>
					<span>运动队：{{ personInfo.department_name }}</span>
				</div>
				<div class="top_title">
					<span></span>
					数据详情
				</div>
				<div class="top_sort_content">
					<div style="flex: 1">
						<el-tooltip class="item" effect="dark" content="请选出数值越小，代表能力越强的指标" placement="top">
							<i class="el-icon-info"></i>
						</el-tooltip>
						请选择倒序排名指标

						<el-select v-model="sortListValue" multiple placeholder="请选择" @change="sortChange">
							<el-option v-for="item in demoSelectData" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
						</el-select>
					</div>
					<div><span></span>最大值 <span></span>最小值</div>
				</div>

				<el-table :data="tableDataList" style="width: 100%" class="sortTable" ref="analysisTable" height="400">
					<el-table-column label="序号" type="index" width="45" align="center" fixed>
						<template slot-scope="scope">{{ scope.$index + 1 == tableDataList.length || scope.$index + 1 == tableDataList.length - 1 ? '' : scope.$index + 1 }}</template>
					</el-table-column>
					<el-table-column label="日期" prop="key" align="center" width="150" fixed> </el-table-column>
					<el-table-column v-for="(item, index) in tableDataHead" :key="'table' + index" :prop="item.uuid" :label="item.norm_name" align="center">
						<template slot-scope="scope">
							<div
								class="valueContent"
								:style="{
									background:
										scope.row[item.uuid].order == 2 && sortListValue.indexOf(item.uuid) !== -1
											? '#ffcb00'
											: scope.row[item.uuid].order == 1 && sortListValue.indexOf(item.uuid) !== -1
											? '#80e386'
											: scope.row[item.uuid].order == 2
											? '#80e386'
											: scope.row[item.uuid].order == 1
											? '#ffcb00'
											: '',
								}"
							>
								{{ scope.$index + 1 == tableDataList.length || scope.$index + 1 == tableDataList.length - 1 ? scope.row[item.uuid] || '-' : scope.row[item.uuid].value || '-' }}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="individual_top_content">
				<div class="top_title_qs">
					<div class="top_title">
						<span></span>
						指标变化趋势
					</div>

					<el-button @click="settingDateStage" class="new_btn" style="background: #fff" operation-button round>设置日期阶段</el-button>
				</div>

				<div class="individual_echarts_content">
					<div v-for="(item, index) in EchartsData" :key="'ech' + index" :id="'bgEcharts' + index" class="bgEcharts"></div>
				</div>
			</div>

			<!-- 测试评价 -->
			<div class="test_content">
				<div class="top_title">
					<span></span>
					测试评价
				</div>
				<!-- v-if="$route.query.name !== 'look'" -->
				<div class="test_editor" id="editor" ref="editorElem" style="width: 100%"></div>
				<!-- <div class="test_editor look_editor" v-else v-html="editorContent">

        </div>-->
			</div>
		</div>

		<el-dialog :close-on-click-modal="false" :visible.sync="settingDateDialog" :before-close="settingDate_cancel" title="设置日期阶段" width="55%" center>
			<div class="addressForm_dialog">
				<div class="add_stageContent">
					<el-button @click="addDateStage" class="new_btn" operation-button round style="background: #1a9f16; color: #fff">增加阶段</el-button>
				</div>
				<el-table :data="dateStageData" style="width: 100%" height="300">
					<el-table-column prop="date" label="阶段名称" align="center" width="200">
						<template slot-scope="scope">
							<el-input v-model="scope.row.title" style="width: 50%" maxlength="15"> </el-input>
						</template>
					</el-table-column>
					<el-table-column prop="name" label="阶段日期范围" align="center">
						<template slot-scope="scope">
							<el-date-picker
								v-model="scope.row.date"
								type="daterange"
								align="right"
								unlink-panels
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								class="picker_date"
								style="width: 80%"
								:picker-options="pickerOptions"
							></el-date-picker>
						</template>
					</el-table-column>
					<el-table-column prop="address" label="操作" width="100" align="center">
						<template slot-scope="scope">
							<el-button type="text" @click="delDateStage(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<p style="margin-top: 10px; color: #ff5656">注：请按照日期先后顺序定义日期阶段</p>
			</div>
			<div class="form-bottom-button">
				<el-button @click="saveDateStage" type="primary" v-no-more-click class="save_btn" round>确定</el-button>
				<el-button @click="settingDate_cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>

		<!-- 测试指标 -->
		<el-dialog :close-on-click-modal="false" :visible.sync="dialogTextIndex" :before-close="text_cancel" title="选择指标" width="50%" center>
			<div class="ModifyDoalog">
				<div class="textDialog_left">
					<div class="textDialog_left_input">
						<el-input
							@keyup.enter.native="selectTextIndex"
							class="left-child-input left-child-input-content"
							clearable
							placeholder="请输入指标名称"
							suffix-icon="iconfont iconsousuo"
							v-model="inputValue"
						></el-input>
					</div>
					<h2>指标列表</h2>
					<div class="tree_demo" v-loading="load">
						<el-tree
							empty-text="暂无数据"
							:data="textTreeData"
							ref="textTreeNode"
							show-checkbox
							node-key="uuid"
							:props="defaultProps"
							:check-strictly="false"
							:check-on-click-node="false"
							:filter-node-method="filterNode"
							@check="checkChange"
						></el-tree>
					</div>
				</div>
				<div class="textDialog_right">
					<p>
						已选指标 ( {{ selectNum }}/{{ textAllNum }} )
						<span @click="clickTreeAll">清空</span>
					</p>
					<div class="tree_demo">
						<el-tree :data="selectTreeData" node-key="uuid" :props="defaultProps">
							<span class="custom-tree-node" slot-scope="{ node, data }">
								<span>{{ node.label }}</span>
								<span>
									<i class="iconfont iconshanchu-moren" @click="textTreeCancel(node, data)" v-if="data.showCheckbox"></i>
								</span>
							</span>
						</el-tree>
					</div>
				</div>
			</div>
			<p class="bz_content">*注：一个指标同属于多个分类，指标只呈现在一个分类中</p>
			<div class="form-bottom-button">
				<el-button @click="text_save" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="text_cancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>

		<el-dialog :close-on-click-modal="false" :visible.sync="saveDialog" :before-close="saveCancel" title="保存为个体分析报告" width="35%" center>
			<el-form ref="saveForm" :model="saveForm" :rules="saveFormRules" label-width="80px">
				<el-form-item label="报告名称" prop="name">
					<el-input v-model="saveForm.name" maxlength="40"></el-input>
				</el-form-item>
			</el-form>
			<p style="text-align: center; margin: 40px 0">注：保存后的个体分析报告可在统计分析>报告管理中查看/删除</p>
			<div class="form-bottom-button">
				<el-button @click="bgSave" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="saveCancel" class="cancel_btn" round>取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import E from 'wangeditor'

export default {
	data() {
		return {
			editor: null,
			editorContent: '',
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			stageDate: [],
			deparmentId: [],
			departmentOptions: [],
			staffList: [],
			targetList: [],
			staffValue: '',
			personInfo: {},

			scopeDate: [],

			settingDateDialog: false,
			dateStageData: [{}, {}, {}],
			newDateStageData: [],
			oldDateStageData: [{}, {}, {}],
			tableData: [],

			demoSelectData: [],
			dialogTextIndex: false,
			inputValue: '',
			load: '',
			textTreeData: [],
			defaultProps: {
				children: 'norms',
				label: 'name',
			},
			selectNum: 0,
			textAllNum: 0,
			selectTreeData: [],
			saveSelectTreeData: [],

			tableDataHead: [],
			tableDataList: [],
			EchartsData: [],

			sortListValue: [],

			saveDialog: false,
			saveFormRules: {
				name: { required: true, message: '请输入报告名称', trigger: 'blur' },
			},
			saveForm: {
				name: '',
			},

			pickerOptions: {
				disabledDate: time => {
					if (!this.scopeDate) {
						return false
					}
					const startTime = new Date(this.scopeDate[0]).getTime()
					const endTime = new Date(this.scopeDate[1]).getTime()
					return time.getTime() < startTime - 8.64e7 || time.getTime() > endTime
				},
			},
		}
	},
	watch: {
		inputValue(val) {
			this.$refs.textTreeNode.filter(val)
		},
		tableDataHead: {
			handler() {
				this.$nextTick(() => {
					this.$refs.analysisTable.doLayout()
				})
			},
			deep: true,
		},
	},
	mounted() {
		this.editor = new E(this.$refs.editorElem)
		// 编辑器的事件，每次改变会获取其html内容
		this.editor.config.onchange = html => {
			this.editorContent = html
		}
		this.editor.config.zIndex = 1000
		// this.editor.config.uploadImgServer = this.$store.state.img_url + '/p/annex/add'
		// this.editor.config.uploadFileName = 'file'
		this.editor.config.uploadImgShowBase64 = true
		this.editor.config.menus = [
			// 菜单配置
			'head', // 标题
			'bold', // 粗体
			'fontSize', // 字号
			'fontName', // 字体
			'italic', // 斜体
			'underline', // 下划线
			'strikeThrough', // 删除线
			'foreColor', // 文字颜色
			'backColor', // 背景颜色
			'link', // 插入链接
			'list', // 列表
			'justify', // 对齐方式
			'quote', // 引用
			'image', // 插入图片
			'table', // 表格
			'code', // 插入代码
		]
		this.editor.create() // 创建富文本实例

		this.getDeparment()
		this.$axios.post('/p/cate/censusNorm').then(res => {
			if (res.data.code == 0) {
				this.targetList = res.data.data.rows
			} else {
				this.$message({
					type: 'error',
					message: res.data.message,
				})
			}
		})
		// this.getData()
	},
	methods: {
		create(date) {
			this.scopeDate = JSON.parse(JSON.stringify(date))
			this.$set(this, 'dateStageData', [{}, {}, {}])
			this.$set(this, 'newDateStageData', [])
			this.$set(this, 'oldDateStageData', [{}, {}, {}])
			if (!this.scopeDate) {
				this.tableDataList = []
				this.tableDataHead = []
				this.EchartsData = []
				// this.$set(this, 'dateStageData', [{}, {}, {}])
				// this.$set(this, 'newDateStageData', [])
				// this.$set(this, 'oldDateStageData', [{}, {}, {}])
			} else {
				this.getData()
			}
		},
		savePersonAnalysis() {
			this.saveDialog = true
		},
		// 获取运动队
		getDeparment() {
			this.$axios.post('/p/staff/tree').then(res => {
				if (res.data.code == 0) {
					this.departmentOptions = res.data.data.department
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}
			})
		},
		getStaffList() {
			this.staffValue = ''
			if (!this.deparmentId.length) {
				this.$set(this, 'staffList', [])
				return false
			}
			return new Promise(() => {
				this.$axios
					.post('/p/staff/lists', {
						department_uuid_str: this.deparmentId.toString(),
					})
					.then(res => {
						if (res.data.code == 0) {
							this.staffList = res.data.data.rows
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
			})
		},
		settingDateStage() {
			console.log(this.stageDate)
			if (!this.stageDate || !this.stageDate.length) {
				return this.$message({
					type: 'warning',
					message: '请选择日期',
				})
			}
			this.settingDateDialog = true
		},
		saveDateStage() {
			var type = false,
				message = ''
			this.dateStageData.forEach((item, index) => {
				if (!item.title && item.date) {
					type = true
					message = '请输入阶段名称'
					return false
				} else if (item.title && !item.date) {
					type = true
					message = '请选择阶段日期范围'
					return false
				}
				this.dateStageData.forEach((itm, idx) => {
					if (item.title == itm.title && index !== idx && item.title) {
						type = true
						message = '阶段名称不可重复'
						return false
					}
				})
			})
			if (type) {
				return this.$message({
					type: 'error',
					message,
				})
			}

			var c_data = JSON.parse(JSON.stringify(this.dateStageData))

			for (let i = c_data.length - 1; i >= 0; i--) {
				if (JSON.stringify(c_data[i]) == '{}') {
					c_data.splice(i, 1)
				} else if (!c_data[i].title && !c_data[i].date) {
					c_data.splice(i, 1)
				}
			}

			this.$axios
				.post('/p/analyse/validateArr', {
					data_arr: JSON.stringify(c_data),
				})
				.then(res => {
					if (res.data.code == 0) {
						this.settingDateDialog = false
						this.newDateStageData = c_data
						this.oldDateStageData = JSON.parse(JSON.stringify(this.dateStageData))

						this.EchartsData.forEach((itm, idx) => {
							this.$nextTick(() => {
								this.setEcharts(itm, idx)
							})
						})
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},
		settingDate_cancel() {
			this.scopeDate = JSON.parse(JSON.stringify(this.stageDate))

			this.settingDateDialog = false
			this.dateStageData = JSON.parse(JSON.stringify(this.oldDateStageData))
		},
		addDateStage() {
			this.dateStageData.push({})
		},
		delDateStage(index) {
			this.dateStageData.splice(index, 1)
		},
		getStaffInfo() {
			this.$axios
				.post('/p/analyse/staffInfo', {
					uuid: this.staffValue,
				})
				.then(res => {
					if (res.data.code == 0) {
						this.personInfo = res.data.data
						this.getData()
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},

		// 指标
		// 选择测试指标
		selectTextIndex(name) {
			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
			this.dialogTextIndex = true
			this.$axios
				.post('p/cate/censusNorm', {})
				.then(res => {
					if (res.data.code == 0) {
						this.textAllNum = res.data.data.total
						this.load = false
						res.data.data.rows.forEach(item => {
							if (item.builtin == 1) {
								item.norms.forEach(itm => {
									itm.builtin = 1
									itm.showCheckbox = false
								})
								item.builtin = 0
								item.showCheckbox = true
							} else {
								item.showCheckbox = true
								item.norms.forEach(itm => {
									itm.showCheckbox = true
								})
							}
						})

						this.oldTreeData = JSON.parse(JSON.stringify(res.data.data.rows))
						this.textTreeData = JSON.parse(JSON.stringify(res.data.data.rows))

						this.$nextTick(() => {
							this.selectTreeData.forEach(item => {
								this.$refs.textTreeNode.setChecked(item.uuid, true)
								item.norms.forEach(itm => {
									this.$refs.textTreeNode.setChecked(itm.uuid, true)
								})
							})
						})
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
				.catch(error => {
					console.log(error)
				})
		},
		filterNode(value, data) {
			if (!value) return true
			return data.name.indexOf(value) !== -1
		},
		demoCancel(uid, index) {
			this.demoSelectData.splice(index, 1)
			this.saveSelectTreeData.forEach((item, idx) => {
				if (item.uuid == uid) {
					this.saveSelectTreeData.splice(idx, 1)
				} else if (item.norms.length) {
					item.norms.forEach((im, ix) => {
						if (im.uuid == uid) {
							item.norms.splice(ix, 1)
						}
					})
					if (!item.norms.length) {
						this.saveSelectTreeData.splice(idx, 1)
					}
				}
			})
			if (this.saveSelectTreeData == []) {
			}

			let nameData = []
			this.saveSelectTreeData.forEach((item, index) => {
				nameData.push(item.name)
			})

			this.selectTextName = nameData
			this.activeName = this.selectTextName[0]

			this.forSelectName = []

			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
			console.log(this.selectTreeData)
			if (this.selectTreeData.length == 0) {
				this.tableDataHead = []
				this.tableDataList = []
				this.EchartsData = []
			}
			this.getData()
		},
		// 关闭弹框
		text_cancel() {
			this.dialogTextIndex = false
			this.selectTreeData = JSON.parse(JSON.stringify(this.saveSelectTreeData))
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},
		// 弹框-保存
		text_save() {
			this.dialogTextIndex = false
			this.demoSelectData = []
			this.forSelectName = []
			this.saveSelectTreeData = JSON.parse(JSON.stringify(this.selectTreeData))
			if (!this.saveSelectTreeData.length) {
				this.forSelectName = []
				this.tableDataHead = []
				this.tableDataList = []
				return
			}
			this.saveSelectTreeData.forEach(item => {
				if (item.norms.length) {
					item.norms.forEach(itm => {
						this.demoSelectData.push(itm)
					})
				} else {
					this.demoSelectData.push(item)
				}
			})

			let nameData = []
			this.saveSelectTreeData.forEach((item, index) => {
				nameData.push(item.name)
			})
			this.selectTextName = nameData
			this.activeName = this.selectTextName[0]

			this.getData()

			console.log(this.demoSelectData, this.saveSelectTreeData, this.activeName)
		},
		// 选择左侧数据
		checkChange(node, data) {
			let is_fit = true

			var selectData = JSON.parse(JSON.stringify(this.selectTreeData))
			selectData.forEach((item, index) => {
				if (item.uuid == node.uuid) {
					is_fit = false
					if (item.norms.length == node.norms.length) {
						selectData.splice(index, 1)
					} else {
						item.norms = node.norms
					}
				} else if (item.norms !== []) {
					item.norms.forEach((itm, idx) => {
						if (itm.uuid == node.uuid) {
							is_fit = false
							selectData[index].norms.splice(idx, 1)
							if (item.norms.length == 0) {
								selectData.splice(index, 1)
							}
						}
					})
				}
			})
			this.selectTreeData = selectData
			if (is_fit) {
				var treeData = JSON.parse(JSON.stringify(this.textTreeData))
				treeData.forEach((im, ix) => {
					var ims = JSON.parse(JSON.stringify(im))
					if (ims.uuid == node.uuid) {
						this.selectTreeData.push(ims)
					} else if (ims.norms !== []) {
						ims.norms.forEach((i, x) => {
							if (i.uuid == node.uuid) {
								let type = true
								this.selectTreeData.forEach((o, m) => {
									if (o.uuid == ims.uuid) {
										type = false
										o.norms.push(i)
									}
								})
								if (type) {
									ims.norms = []
									ims.norms.push(i)
									this.selectTreeData.push(ims)
								}
							}
						})
					}
				})
			}
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},
		clickTreeAll() {
			if (this.selectTreeData == []) {
			}
			this.selectTreeData.forEach((item, index) => {
				this.$refs.textTreeNode.setChecked(item.uuid, false)
				item.norms.forEach(itm => {
					this.$refs.textTreeNode.setChecked(itm.uuid, false)
				})
			})
			this.selectTreeData = []
			this.selectNum = 0
		},
		textTreeCancel(node, data) {
			this.$refs.textTreeNode.setChecked(data.uuid, false)

			this.selectTreeData.forEach((item, index) => {
				if (item.uuid == data.uuid) {
					item.norms.forEach(itm => {
						this.$refs.textTreeNode.setChecked(itm.uuid, false)
					})
					this.selectTreeData.splice(index, 1)
				} else if (item.norms !== []) {
					item.norms.forEach((itm, idx) => {
						if (itm.uuid == data.uuid) {
							item.norms.splice(idx, 1)
							if (item.norms.length == 0) {
								this.selectTreeData.splice(index, 1)
							}
						}
					})
				}
			})
			this.selectNum = 0
			this.selectTreeData.forEach(item => {
				if (item.norms.length) {
					this.selectNum += item.norms.length
				} else {
					this.selectNum += 1
				}
			})
		},

		sortChange() {
			console.log(this.demoSelectData)
		},

		getData() {
			if (!this.demoSelectData.length || this.staffValue == '') {
				return false
			}
			var str = []
			this.demoSelectData.forEach(itm => {
				str.push(itm.uuid)
			})
			this.$axios
				.post(
					'/p/analyse/analyse',
					this.$qs({
						// start_date: '2023-03-01',
						// end_date: '2023-03-31',
						// staff_uuid: 'CF1D67C8-7397-4736-FF2C-CA88C8160E9D',
						// norm_uuid_str: '0DD9CC76-91A0-498B-5844-75C159BEA6A2,462676E6-9F86-BBF1-F243-9C88C2FBDEB8',
						start_date: this.stageDate ? this.stageDate[0] : '',
						end_date: this.stageDate ? this.stageDate[1] : '',
						staff_uuid: this.staffValue,
						norm_uuid_str: str.toString(''),
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.tableDataHead = res.data.data.norm_info
						this.tableDataList = res.data.data.row
						this.EchartsData = res.data.data.chart

						res.data.data.chart.forEach((itm, idx) => {
							this.$nextTick(() => {
								this.setEcharts(itm, idx)
							})
						})
						// console.log(this.tableDataHead, this.tableDataList)
						// 			tableDataHead: [],
						// tableDataList: [],
						// EchartsData: [],
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},

		setEcharts(rows, idx) {
			var name = 'bgEcharts' + idx
			var backgrounds = ['#D6FFD9', '#DBECFF', '#FFFCBB', '#FFE5E5', '#E1D6FF', '#D6FCFF'],
				colors = ['#56C15D', '#5C87B8', '#9B950C', '#CC6969', '#856ACE', '#4BA9B0']
			var x_data = [],
				y_data = []
			for (var i in rows.data) {
				x_data.push(i)
				y_data.push(rows.data[i])
			}
			var oldStageData = JSON.parse(JSON.stringify(this.newDateStageData))
			var newStageData = this.getDateTime(oldStageData, x_data)
			let serum = this.$echarts.init(document.getElementById(name))
			var optionS = {
				title: {
					text: rows.norm_name + '历史成绩',
					// textAlign: 'center',
					// textVerticalAlign: 'center',
					top: '5%',
					left: 'center',
				},
				grid: {
					top: '25%',
					left: '10%',
					right: '10%',
					bottom: '20%',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
					},
				},
				xAxis: {
					type: 'category',
					data: x_data,
					nameLocation: 'end',
					boundaryGap: ['20%', '20%'],
					alignWithLabel: true,
					axisLabel: {
						color: '#333',
						rotate: 40,
					},
					axisTick: {
						show: false,
					},
					axisLine: {
						lineStyle: {
							color: '#E1E1E1',
						},
					},
				},
				yAxis: {
					show: true,
					name: '单位: ' + rows.unit_name || '',
					type: 'value',
					splitLine: {
						//网格线
						show: true,
						lineStyle: {
							color: '#E1E1E1',
							type: 'dashed',
						},
					},
					axisLine: { show: false, lineStyle: { color: '#333' } },
					axisTick: {
						show: false,
					},
					nameTextStyle: {
						padding: [0, 0, 10, 0],
					},
				},
				// visualMap: {
				// 	show: false,
				// 	dimension: 0,
				// 	pieces: [],
				// },
				color: ['#1790FF'],
				series: [
					{
						name: '',
						type: 'line',
						smooth: false,
						symbolSize: 10,
						// prettier-ignore
						data: y_data,
						label: {
							show: true,
						},
						markLine: {
							data: [
								{
									type: 'average',
									name: 'Avg',
									label: {
										show: true,
										formatter: '平均值:{c}',
										position: 'insideEndTop',
									},
								},
							],
						},
						markArea: {
							data: [],
						},
					},
				],
			}
			var f_data = []
			newStageData.forEach((item, index) => {
				if (!item.readTime[1]) {
					return false
				}
				f_data.push([
					{
						name: item.title,
						xAxis: item.readTime ? item.readTime[0] : '',
						itemStyle: {
							color: backgrounds[index],
							borderWidth: 10,
							borderColor: backgrounds[index],
						},
						label: {
							offset: [0, 30],
							color: colors[index],
						},
					},
					{
						xAxis: item.readTime ? item.readTime[1] : '',
					},
				])
			})
			optionS.series[0].markArea.data = f_data
			serum.setOption(optionS, false)
			this.$echartsResize(serum)
		},

		getDateTime(oldStageData, valueData) {
			oldStageData.forEach((itm, idx) => {
				itm.readTime = []
				var start_idx = '',
					end_idx = ''
				var timeStart = new Date(itm.date[0]).getTime()
				var timeEnd = new Date(itm.date[1]).getTime()
				for (var i = 0; i < valueData.length; i++) {
					var cime = new Date(valueData[i]).getTime()
					if (timeStart <= cime && timeEnd >= cime) {
						start_idx = i
						break
					}
				}
				for (var i = valueData.length - 1; i >= 0; i--) {
					var cime = new Date(valueData[i]).getTime()
					if (timeEnd >= cime && cime >= timeStart) {
						end_idx = i
						break
					}
				}
				itm.readTime = [valueData[start_idx], valueData[end_idx]]
			})
			return oldStageData
		},

		bgSave() {
			this.$refs['saveForm'].validate(valid => {
				if (valid) {
					var obj = {
						title: this.saveForm.name,
						start_date: this.stageDate[0],
						end_date: this.stageDate[1],
						staff_uuid: this.staffValue,
						report: this.editorContent,
					}
					// 指标uuid
					var uStr = []
					console.log(this.demoSelectData)
					this.demoSelectData.forEach(itm => {
						uStr.push(itm.name)
					})
					obj.norm_uuid_str = uStr.toString('')
					// 倒序uuid
					obj.norm_uuid_str_selected = this.sortListValue.toString('')
					var c_name = []
					this.sortListValue.forEach(itm => {
						this.demoSelectData.forEach(im => {
							if (itm == im.uuid) {
								c_name.push(im.name)
							}
						})
					})
					obj.norm_uuid_str_selected_name = c_name.toString('')
					// 日期阶段
					obj.date_str = JSON.stringify(this.newDateStageData)
					// tabel数据
					// obj.detail = JSON.stringify(this.tableDataList)
					obj.detail = {
						chart: this.EchartsData,
						norm_info: this.tableDataHead,
						row: this.tableDataList,
						staff_info: this.personInfo,
					}

					this.$axios.post('/p/analyse/add', obj).then(res => {
						if (res.data.code == 0) {
							this.$message({
								type: 'success',
								message: res.data.message,
							})
							this.$router.push({
								path: '/analysis/reportManagement',
								query: {
									type: 2,
								},
							})
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},
		saveCancel() {
			this.$refs['saveForm'].resetFields()
			this.saveDialog = false
		},
	},
}
</script>

<style lang="scss" scoped>
.body-function {
	padding-top: 10px;
}
.child-top {
	border-radius: 5px;
	.input-from {
		// float: left;
		flex: 1;
		min-width: 1100px;
		margin-bottom: 0;

		.picker_date {
			float: left;
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 20px;
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;
		}

		.el-cascader {
			margin-top: 18px;
			min-width: 200px;
		}

		.searchBtn {
			margin-top: 20px;
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;
		.el-button {
			width: auto;
		}
	}
}

.individual_top_content {
	padding: 20px;
	margin-bottom: 10px;
	background: #fff;
	border-radius: 5px;
}

.member_content_top {
	height: 70px;
	display: flex;
	align-items: center;
	padding-left: 15px;
	margin-bottom: 20px;
	border: 1px solid #e3e3e3;
	.el-avatar {
		width: 50px;
		height: 50px;
		line-height: 50px;
	}
	img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
	span {
		font-size: 18px;
		color: #333;
		margin-left: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		display: inline-block;
		max-width: 300px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
		&:first-child {
			margin-left: 0px;
		}
		&:nth-child(2) {
			margin-left: 20px;
		}
	}
}

.top_title_qs {
	display: flex;
	justify-content: space-between;
	.el-button {
		border: 1px #0055e9 solid;
		color: #0055e9;
	}
}
.individual_echarts_content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	div {
		width: 48%;
		height: 400px;
		border: 1px solid #e3e3e3;
		margin-top: 20px;
	}
}
.top_title {
	display: flex;
	align-items: center;
	font-size: 18px;
	color: #333;
	font-weight: 500;
	font-family: PingFangSC-Medium, PingFang SC;
	span {
		display: inline-block;
		width: 6px;
		height: 20px;
		background: #0055e9;
		border-radius: 3px;
		margin-right: 10px;
	}
}

.top_sort_content {
	color: #666;
	font-size: 16px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 20px;
	i {
		font-size: 20px;
		margin-right: 20px;
	}
	span {
		display: inline-block;
		width: 18px;
		height: 14px;
		background: #80e386;
		margin-left: 20px;
		margin-right: 5px;
		&:last-child {
			background: #ffcb00;
		}
	}
	.el-select {
		margin-left: 15px;
		width: 50%;
	}
}
.sortTable {
	margin-left: 20px;
	border: 1px solid #ebeef5;

	/deep/ tbody > tr > td {
		padding: 0 !important;
	}
	/deep/ .cell {
		padding: 0 !important;
	}
	.valueContent {
		height: 50px;
		line-height: 50px;
		// line-height: 30px;
		// padding: 10px 8px;
	}
}

.add_stageContent {
	margin-bottom: 10px;
	text-align: right;
}

.addressForm_dialog {
	.el-table {
		border: 1px solid #e3e3e3;

		.el-button {
			color: #424242;
			&:hover {
				color: #ff5656;
			}
		}
	}
	.el-date-editor {
		margin-right: 1.04167rem;
		border-radius: 0;
		border: none;
		border-bottom: 2px solid #ccc;
	}
}

.ModifyDoalog {
	display: flex;
	height: 400px;
	padding: 0 30px;
	justify-content: space-between;
	margin-bottom: 10px;
	.textDialog_left {
		width: 45%;
		border: 1px solid #e3e3e3;
		overflow: hidden;
		.textDialog_left_input {
			padding: 10px 20px;
		}
		h2 {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border: 1px solid #e3e3e3;
			border-left: none;
			border-right: none;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			margin-top: 5px;
		}
		.tree_demo {
			height: 280px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}
			}
		}
	}
	.textDialog_right {
		width: 45%;
		border: 1px solid #e3e3e3;
		p {
			height: 50px;
			line-height: 50px;
			background: #f5f5f5;
			border-radius: 2px 2px 0px 0px;
			border-bottom: 1px solid #e3e3e3;
			font-size: 16px;
			font-weight: 400;
			text-align: center;
			color: #333;
			position: relative;
			span {
				position: absolute;
				right: 20px;
				font-size: 14px;
				font-family: MicrosoftYaHei;
				color: #999999;
				&:hover {
					cursor: pointer;
				}
			}
		}
		.tree_demo {
			height: 345px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			.el-tree {
				font-size: 16px;
				/deep/ .el-tree-node {
					margin: 5px 0;
					.el-checkbox {
						margin-right: 10px;
						.is-checked {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
						.is-indeterminate {
							.el-checkbox__inner {
								background-color: #0055e9;
								border-color: #0055e9;
							}
						}
					}
					.el-tree-node__label {
						color: #333;
					}
				}

				.custom-tree-node {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 14px;
					padding-right: 8px;
					i {
						&:hover {
							color: #f00;
						}
					}
				}
			}
		}
	}
}

.text_demo {
	width: 30%;
	min-height: 40px;
	line-height: 40px;
	border-bottom: 2px solid #ccc;
	padding-left: 5px;
	margin-top: 18px;
	float: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	span {
		padding: 5px 3px 5px 10px;
		border-radius: 5px;
		background-color: #f4f4f5;
		border-color: #e9e9eb;
		color: #909399;
		font-size: 14px;
		margin-right: 7px;
		margin-bottom: 3px;
		i {
			border-radius: 50%;
			background-color: #c0c4cc;
			font-size: 14px;
			color: #909399;
			margin-left: 5px;
			&:hover {
				color: #fff;
				cursor: pointer;
			}
		}
	}
	p {
		padding-left: 10px;
		color: #c2c2c2;
		font-size: 14px;
	}
}

// ------------------
// 测试评价
.test_content {
	background: #fff;
	height: auto;
	padding: 40px 100px 30px 50px;
	margin-top: 10px;
	border-radius: 4px;
	.top_title {
		margin-left: 0;
	}
	.test_editor {
		width: 100%;
		margin-top: 40px;
		height: 400px;
	}
	.look_editor {
		overflow: auto;
		border: 1px solid #f5f7fa;
		border-radius: 5px;
		padding: 20px;
		box-sizing: border-box;
		/* table 样式 */
		table {
			border-top: 1px solid #ccc;
			border-left: 1px solid #ccc;
		}
		table td,
		table th {
			border-bottom: 1px solid #ccc;
			border-right: 1px solid #ccc;
			padding: 3px 5px;
		}
		table th {
			border-bottom: 2px solid #ccc;
			text-align: center;
		}

		/* blockquote 样式 */
		blockquote {
			display: block;
			border-left: 8px solid #d0e5f2;
			padding: 5px 10px;
			margin: 10px 0;
			line-height: 1.4;
			font-size: 100%;
			background-color: #f1f1f1;
		}

		/* code 样式 */
		code {
			display: inline-block;
			*display: inline;
			*zoom: 1;
			background-color: #f1f1f1;
			border-radius: 3px;
			padding: 3px 5px;
			margin: 0 3px;
		}
		pre code {
			display: block;
		}

		/* ul ol 样式 */
		ul,
		ol {
			margin: 10px 0 10px 20px;
		}
	}
}
</style>
